import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Text, { TEXT_COLOR } from '@components/text';
import {
  Wrapper, ContentWrapper, ListWrapper, ListItem, ListItemHeader, ListItemBody,
} from './styles';

class FAQSection extends Component {
  constructor() {
    super();
    this.state = {
      shownItemIndex: null,
    };
  }

  handleListHeaderClick = (index) => {
    const { shownItemIndex } = this.state;
    const nextIndex = index === shownItemIndex ? null : index;
    this.setState({ shownItemIndex: nextIndex });
  }

  renderList = (splitContent) => {
    const { shownItemIndex } = this.state;
    // debugger;

    return (
      <ListWrapper>
        {splitContent.map((html, index) => {
          const isBodyShown = shownItemIndex === index;
          const header = html.match(/<h4>.+<\/h4>/);
          const body = html.replace(/<h4>.+<\/h4>/, '');
          return (
            <ListItem key={index} isBodyShown={isBodyShown}>
              <ListItemHeader isBodyShown={isBodyShown} onClick={() => this.handleListHeaderClick(index)}>
                <Text
                  fontSize={21}
                  color={TEXT_COLOR.DARK_GRAY}
                  dangerouslySetInnerHTML={{ __html: header }}
                />
              </ListItemHeader>
              <ListItemBody isBodyShown={isBodyShown}>
                <Text
                  fontSize={18}
                  color={TEXT_COLOR.GRAY}
                  dangerouslySetInnerHTML={{ __html: body }}
                />
              </ListItemBody>
            </ListItem>
          );
        })}
      </ListWrapper>
    );
  }

  render() {
    const { content } = this.props;
    const splittedContent = content.split('<hr>');
    return (
      <Wrapper>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: splittedContent[0] }} />
          {this.renderList(splittedContent.slice(1))}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

FAQSection.propTypes = {
  content: PropTypes.string,
};

export default FAQSection;
