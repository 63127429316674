import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
});

export const ContentWrapper = styled.div({
  margin: '0 9.5%',
  '& h2': {
    marginBottom: 40,
    textAlign: 'center',
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
}));

export const ListWrapper = styled.ul({
  listStyle: 'none',
  marginBottom: 130,
});

export const ListItem = styled.li({
  marginBottom: 24,
},
({ theme, isBodyShown }) => ({
  '@media (max-width: 480px)': {
    boxShadow: isBodyShown ? theme.shadows.syllabusListItemOpened : theme.shadows.syllabusListItem,
  },
}));

export const ListItemHeader = styled.div({
  minHeight: 74,
  padding: '10px 24px 10px 75px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '@media (max-width: 480px)': {
    minHeight: 41,
    padding: 10,
    boxShadow: 'none',
    '& h4': {
      fontSize: 15,
      lineHeight: '19px',
    },
  },
},
({ theme, isBodyShown }) => ({
  boxShadow: isBodyShown ? theme.shadows.syllabusListItemOpened : theme.shadows.syllabusListItem,
}));

export const ListItemBody = styled.div({
  marginTop: 9,
  padding: '20px 75px',
  overflow: 'hidden',
  '& p': {
    marginBottom: 15,
    lineHeight: '27px',
  },
  '& p:last-of-type': {
    marginBottom: 0,
  },
  '& li': {
    marginBottom: 7,
    lineHeight: '27px',
  },
  '@media (max-width: 480px)': {
    marginTop: 0,
    padding: '5px 10px 20px',
    boxShadow: 'none',
    '& li': {
      display: 'list-item',
      listStyle: 'circle',
      marginLeft: 18,
      marginBottom: 7,
      lineHeight: '19px',
    },
    '& p': {
      lineHeight: '19px',
    },
    '& p:last-of-type': {
      marginBottom: 20,
    },
  },
},
({ theme, isBodyShown }) => ({
  display: isBodyShown ? 'block' : 'none',
  boxShadow: theme.shadows.syllabusListItemOpened,
}));
