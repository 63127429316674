import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { submitFormData } from '@utils/hubspot';

import Button from '@components/button';
import {
  Wrapper, ContentWrapper, StyledForm, StyledInput,
} from './styles';

class HelpSection extends Component {
  constructor() {
    super();
    this.state = {
      inputValue: '',
      isDataSubmitted: false,
      hasRequestError: false,
      hasFieldError: false,
    };
  }

  handleInput = (e) => {
    const { value } = e.target;
    const { hasRequestError, hasFieldError } = this.state;
    if (hasRequestError) {
      this.setState({ hasRequestError: false });
    }
    if (hasFieldError) {
      this.setState({ hasFieldError: false });
    }
    this.setState({ inputValue: value });
  }

  handleRequestResult = (isDataSubmitted) => {
    if (isDataSubmitted) {
      this.setState({
        inputValue: '',
        isDataSubmitted,
      });
    } else {
      this.setState({
        hasRequestError: true,
      });
    }
  }

  handleSubmit = (e) => {
    const { inputValue } = this.state;
    e.preventDefault();
    if (inputValue.length) {
      submitFormData({
        message: inputValue,
        page: 'FAQ',
        onRequest: this.handleRequestResult,
      });
    } else {
      this.setState({
        hasFieldError: true,
      });
    }
  }

  getButtonText = () => {
    const { isDataSubmitted, hasRequestError } = this.state;
    return (isDataSubmitted && 'Thank you')
      || (hasRequestError && 'Error') || 'Send';
  }

  renderForm = () => {
    const {
      inputValue, isDataSubmitted, hasRequestError, hasFieldError,
    } = this.state;
    return (
      <StyledForm>
        <StyledInput
          type="text"
          onChange={this.handleInput}
          value={inputValue}
          placeholder={isDataSubmitted ? 'Question is sent' : 'Type your question'}
          disabled={isDataSubmitted}
          hasError={hasFieldError}
        />
        <Button
          type="submit"
          onClick={this.handleSubmit}
          width={130}
          height={32}
          success={isDataSubmitted}
          alert={hasRequestError}
        >
          {this.getButtonText()}
        </Button>
      </StyledForm>
    );
  };

  render() {
    const { content } = this.props;
    return (
      <Wrapper>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {this.renderForm()}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

HelpSection.propTypes = {
  content: PropTypes.string,
};

export default HelpSection;
