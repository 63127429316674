import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import JuniorImg from '@assets/images/getStarted_junior.svg';
import RefresherImg from '@assets/images/getStarted_refresher.svg';
import ChronicImg from '@assets/images/getStarted_chronic.svg';
import JuniorMobileImg from '@assets/images/getStarted_junior_mobile.svg';
import RefresherMobileImg from '@assets/images/getStarted_refresher_mobile.svg';
import ChronicMobileImg from '@assets/images/getStarted_chronic_mobile.svg';
import { COURSE_TYPES } from '@utils/constants';
import Button from '@components/button';
import {
  Wrapper, ContentWrapper, StyledImage, StyledMobileImage,
} from './styles';

const contentQuery = graphql`{
  cockpitshared {
    getStarted: childCockpitsharedGetStartedTextNode {
      childMarkdownRemark {
        html
      }
    }
  }
}`;

const GetStarted = ({ type, margin }) => {
  let image;
  let mobileImage;
  switch (type) {
    case COURSE_TYPES.ACCELERATOR:
      image = RefresherImg;
      mobileImage = RefresherMobileImg;
      break;
    case COURSE_TYPES.NOVOTALK:
      image = ChronicImg;
      mobileImage = ChronicMobileImg;
      break;
    default:
      image = JuniorImg;
      mobileImage = JuniorMobileImg;
  }
  return (
    <Wrapper margin={margin}>
      <ContentWrapper>
        <StaticQuery
          query={contentQuery}
          render={({ cockpitshared }) => (
            <div dangerouslySetInnerHTML={{ __html: cockpitshared.getStarted.childMarkdownRemark.html }} />
          )}
        />
        <Button
          href="/sign-up/index.html"
          width={200}
          height={58}
          fontSize={18}
        >
          Sign up
        </Button>
      </ContentWrapper>
      <StyledImage src={image} />
      <StyledMobileImage src={mobileImage} />
    </Wrapper>
  );
};

GetStarted.propTypes = {
  type: PropTypes.string,
  margin: PropTypes.number,
};

export default GetStarted;
