import https from 'https';

export const submitFormData = ({
  name, email, message, page, onRequest,
}) => {
  const postData = JSON.stringify({
    fields: [{
      name: 'firstname',
      value: name || '',
    },
    {
      name: 'email',
      value: email || '',
    },
    {
      name: 'message',
      value: message || '',
    },
    {
      name: 'website',
      value: page || '',
    }],
    context: {
      pageUri: window.location.href, // eslint-disable-line no-undef
      pageName: page,
    },
  });

  const options = {
    hostname: 'api.hsforms.com',
    path: '/submissions/v3/integration/submit/2991165/6038e8b0-cb31-43f8-a173-5ece541c0329',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const request = https.request(options, (response) => {
    response.setEncoding('utf8');
    response.on('data', (chunk) => {
      const data = JSON.parse(chunk);
      console.log(data.inlineMessage || data.message);
      onRequest(!!data.inlineMessage);
    });
  });

  request.on('error', (error) => {
    console.error(`Error: ${error.message}`);
    onRequest(false);
  });
  request.write(postData);
  request.end();
};


export const submitSignUpFormData = ({
  firstname, lastname, email, dateOfBirth, country, state, onRequest,
}) => {
  const postData = JSON.stringify({
    fields: [{
      name: 'firstname',
      value: firstname || '',
    },
    {
      name: 'lastname',
      value: lastname || '',
    },
    {
      name: 'email',
      value: email || '',
    },
    {
      name: 'date_of_birth',
      value: dateOfBirth || '',
    },
    {
      name: 'country',
      value: country || '',
    },
    {
      name: 'state',
      value: state || '',
    }],
    context: {
      pageUri: window.location.href, // eslint-disable-line no-undef
    },
  });

  const options = {
    hostname: 'api.hsforms.com',
    path: '/submissions/v3/integration/submit/2991165/3f47a5cd-37d8-4d35-ab67-62059879778d',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const request = https.request(options, (response) => {
    response.setEncoding('utf8');
    response.on('data', (chunk) => {
      const data = JSON.parse(chunk);
      console.log(data.inlineMessage || data.message);
      onRequest(!!data.inlineMessage);
    });
  });

  request.on('error', (error) => {
    console.error(`Error: ${error.message}`);
    onRequest(false);
  });
  request.write(postData);
  request.end();
};
