import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  position: 'relative',
  '@media (max-width: 480px)': {
    paddingTop: 110,
    marginBottom: 100,
  },
},
({ theme, margin }) => ({
  marginBottom: margin,
  '@media (max-width: 480px)': {
    marginBottom: margin / 2,
    backgroundColor: theme.colors.backgroundBlue,
  },
}));

export const ContentWrapper = styled.div({
  position: 'absolute',
  top: 54,
  width: '100%',
  textAlign: 'center',
  '& h2': {
    fontWeight: 'bold',
    marginBottom: 16,
  },
  '& h3': {
    marginBottom: 72,
  },
  '@media (max-width: 480px)': {
    position: 'initial',
    padding: '0 50px',
    '& h3': {
      marginBottom: 34,
      lineHeight: '26px',
    },
    '& a': {
      width: 130,
      height: 32,
      lineHeight: '32px',
      fontSize: 12,
      marginBottom: 80,
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& h3': {
    color: theme.colors.textGray,
  },
}));

export const StyledImage = styled.img({
  width: '100%',
  '@media (max-width: 480px)': {
    display: 'none',
  },
});

export const StyledMobileImage = styled.img({
  width: '100%',
  display: 'none',
  '@media (max-width: 480px)': {
    display: 'block',
  },
});
