import styled from '@emotion/styled';

const LIGHT_GRAY = 'light_gray';
const GRAY = 'gray';
const DARK_GRAY = 'dark_gray';
const REVERSED = 'reversed';
const HIGHLIGHTED = 'highlighted';

export const TEXT_COLOR = {
  LIGHT_GRAY,
  GRAY,
  DARK_GRAY,
  HIGHLIGHTED,
  REVERSED,
};

const getColor = (theme, textColor) => {
  let color;
  switch (textColor) {
    case TEXT_COLOR.LIGHT_GRAY:
      color = theme.colors.textLightGray;
      break;
    case TEXT_COLOR.GRAY:
      color = theme.colors.textGray;
      break;
    case TEXT_COLOR.DARK_GRAY:
      color = theme.colors.textDarkGray;
      break;
    case TEXT_COLOR.HIGHLIGHTED:
      color = theme.colors.textHighlighted;
      break;
    case TEXT_COLOR.REVERSED:
      color = theme.colors.textReversed;
      break;
    default:
      return '#000';
  }
  return color;
};

const Text = styled.span(
  ({
    theme, fontSize, margin, color, fontWeight, textTransform,
  }) => ({
    fontSize: fontSize || 16,
    margin: margin || 0,
    color: getColor(theme, color),
    fontWeight: fontWeight || 'normal',
    textTransform: textTransform || 'none',
  })
);

export default Text;
