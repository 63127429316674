import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '@components/seo';
import MainLayout from '@layouts/mainLayout';
import FAQSection from '@components/pages/faq/faq';
import HelpSection from '@components/pages/faq/help';
import GetStarted from '@components/getStarted';

class FAQPage extends Component {
  getSectionContent = (section) => {
    const { data } = this.props;
    return data.cockpitfaq[section].childMarkdownRemark.html;
  }

  render() {
    return (
      <MainLayout>
        {/* TODO: add keywords */}
        <SEO title="FAQ" keywords={[]} />
        <FAQSection content={this.getSectionContent('faq')} />
        <HelpSection content={this.getSectionContent('help')} />
        <GetStarted margin={122} />
      </MainLayout>
    );
  }
}

FAQPage.propTypes = {
  data: PropTypes.object,
};

export default FAQPage;

export const query = graphql`
  query FAQQuery {
    cockpitfaq {
      faq: childCockpitfaqFaqTextNode {
        childMarkdownRemark {
          html
        }
      }
      help: childCockpitfaqHelpTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
